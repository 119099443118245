'use strict'

const _ = require('lodash')

function getDataFixedPage(fetchFunc, request) {
    const requestToSend = {
        url: request.url,
        dataType: 'json',
        type: 'GET',
        cache: 'default',
        name: request.name
    }

    requestToSend.success = resData => {
        _.invoke(request, 'success', resData)
    }

    requestToSend.error = (xhrRequest, err) => {
        _.invoke(request, 'error', {
            name: err,
            responseText: xhrRequest.responseText,
            status: xhrRequest.status
        })
    }

    _.invoke(request, 'onBeforeFetch')

    fetchFunc(requestToSend)
}


module.exports = {
    getDataFixedPage
}
